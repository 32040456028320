import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './tailwind.generated.css'
import './assets/fonts/fonts.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import App from './App'
import 'firebase/app'
import { FirebaseAppProvider } from 'reactfire'
import Loader from './components/loader/loader'

const firebaseConfig = {
  apiKey: 'AIzaSyDjy3ZirSGK9mgXv5fRdmdDDN4BtnxdShY',
  authDomain: 'recruitments-gdsc.firebaseapp.com',
  projectId: 'recruitments-gdsc',
  storageBucket: 'recruitments-gdsc.appspot.com',
  messagingSenderId: '36684548181',
  appId: '1:36684548181:web:50854d6a93fb9ba2beecdc',
  measurementId: 'G-41GKZ599N'
}

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </FirebaseAppProvider>
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
